<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-dialog v-model="dialog" width="700" transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on"
                            @click="getCustomersReference()"><v-icon>mdi-plus</v-icon> زیادکردنی وەرگرتنەوەی قەرز</v-btn>
                    </template>
                    <v-card>
                        <v-toolbar dark outlined :class="(isUpdate ? 'orange accent-2' : 'blue accent-2')" elevation="1">
                            <v-btn icon @click="dialog = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn text @click="isUpdate ? update() : create()">
                                    <v-icon>{{ isUpdate ? 'mdi-check' : 'mdi-plus' }}</v-icon> {{ isUpdate ? 'نوێکردنەوەی
                                                                        گەڕاندنەوەی قەرز': 'زیادکردنی گەڕاندنەوەی قەرز'}} </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-form ref="give_debt">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-autocomplete :items="customerReference" item-text="customer_name"
                                            item-value="customer_id" dense outlined label="کڕیار"
                                            v-model="give_debt.customer_id"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" :rules="rules.numberRule"
                                            prepend-inner-icon="mdi-currency-usd" dense label="بڕی" outlined
                                            v-model="give_debt.amount" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" dense label="تێبینی" outlined
                                            v-model="give_debt.note"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field @focus="$event.target.select()" v-model="give_debt.date"
                                                    label="بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                            </template>
                                            <v-date-picker v-model="give_debt.date" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="6" lg="6" sm="12">
                                        <v-text-field @focus="$event.target.select()" :rules="rules.numberRule" dense
                                            label="داشکاندن" outlined v-model="give_debt.discount"
                                            type="number"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-card>
                    <v-data-table :headers="headers" :items="give_debts" hide-default-footer :items-per-page="20"
                        :loading="loading" no-data-text="هیچ گەڕاندنەوەیەک نیە  ">
                        <template v-slot:top>
                            <v-text-field @focus="$event.target.select()" v-model="table.search" label="گەڕان"
                                class="mx-4"></v-text-field>
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ '$' + item.amount.toLocaleString() }}
                        </template>
                        <template v-slot:[`item.discount`]="{ item }">
                            {{ '$' + item.discount.toLocaleString() }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="50" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="primary accent-2" id="icon"
                                    @click="print(item.give_debt_id)">mdi-eye</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-eye</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon"
                                    @click="give_debt = { ...removeProperty(item, ['customer_name']) }; dialog = true; getCustomersReference()">mdi-pencil</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon"
                                    @click="remove(item.give_debt_id)">mdi-delete</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-delete</v-icon>
                            </v-hover>
                        </template>
                    </v-data-table>
                    <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfGiveDebts / 20)"
                        v-model="table.page"></v-pagination>
                </v-card>
            </v-col>
        </v-row>
        <notification />
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfGiveDebts: 0,
                search: ''
            },
            dialog: false,
            menu2: false,
            memberReference: [],
            customerReference: [],
            give_debt: {
                give_debt_id: 0,
                customer_id: 0,
                amount: 0,
                note: '',
                date: new Date().toISOString().split("T")[0],
                discount: 0
            },
            loading: false,
            give_debts: [],
            headers: [
                { text: 'ژمارەی وەصل', value: 'give_debt_id', align: 'center' },
                { text: 'کڕیار', value: 'customer_name', align: 'center' },
                { text: 'بڕی', value: 'amount', align: 'center' },
                { text: 'لە بەرواری', value: 'date', align: 'center' },
                { text: 'داشکاندن', value: 'discount', align: 'center' },
                { text: 'تێبینی', value: 'note', align: 'center' },
                { text: 'کردارەکان', value: 'actions', align: 'center' },
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return (v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
                numberRule: [
                    (v) => {
                        return (v >= 0 || 'ژمارەکە پێویستە لە 0 زیاتربێت')
                    }
                ]
            }
        }
    },
    methods: {
        async getGiveDebt() {
            try {
                this.loading = true
                const { data } = await this.axios.get('/give_debt', { params: { page: this.table.page, search: this.table.search } })

                data.give_debt.forEach(give_debt => {
                    give_debt.date = new Date(give_debt.date).toISOString().split("T")[0]
                });
                this.give_debts = data.give_debt
                this.table.numberOfGiveDebts = data.count
                this.loading = false
            } catch (error) {
                console.log(error);
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },
        print(id) {
            // navigate to print page
            this.$router.push('/give_debt/' + id)
        },
        async getCustomersReference() {
            try {
                const { data } = await this.axios.get('/customer/forReference')
                this.customerReference = data
            } catch (error) {
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },
        async create() {
            if (this.$refs.give_debt.validate()) {
                try {
                    const give_debt = { ...this.give_debt }
                    await this.axios.post('/give_debt', give_debt)
                    this.reset()
                    this.getGiveDebt()
                } catch (error) {
                    console.log(error)
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            }
        },
        async update() {
            if (this.$refs.give_debt.validate()) {
                try {
                    const give_debt = { ...this.give_debt }
                    await this.axios.patch('/give_debt/' + this.give_debt.give_debt_id, give_debt)
                    this.getGiveDebt()
                    this.reset()
                    this.dialog = false
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            }
        },
        remove(id) {
            this.$confirm('ئایا دڵنیایی لە سڕینەوەی ئەم وەصلە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/give_debt/' + id)
                    this.getGiveDebt()
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            })
        },
        generateDate(date) {
            const newDate = new Date()
            newDate.setDate(date.split('-')[2])
            newDate.setMonth(date.split('-')[1] - 1)
            newDate.setFullYear(date.split('-')[0])
            return newDate
        },
        reset() {
            this.give_debt = {
                give_debt_id: 0,
                customer_id: 0,
                amount: 0,
                note: '',
                date: new Date().toISOString().split("T")[0],
                discount: 0
            }
            this.$refs.give_debt.resetValidation();
        },
        removeProperty({ ...obj } = {}, fields) {
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
            return obj
        }
    },
    created() {
        this.getGiveDebt()
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.memberReference = []
                this.customerReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getGiveDebt()
        },
        'table.page': function () {
            this.getGiveDebt()
        }
    },
    computed: {
        isUpdate() {
            return this.give_debt.give_debt_id
        }
    }
}
</script>

<style></style>